body {
  background-color: #1aebb6;
  padding: 0;
  box-sizing: border-box;
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.login-page {
  position: relative;
  width: 100%;
  background-color: linear-gradient(to right, #1aebb6 44%, white 10%);
  background-size: cover;
  background-repeat: no-repeat;
}

.register-page {
  background-color: #1aebb6;
}

.login-page,
.register {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.login span:hover {
  cursor: pointer;
}
.register span:hover {
  cursor: pointer;
}

.notes-page {
  width: 100%;
  margin-top: 100px;
}

.notes-page header {
  z-index: 999;
  position: fixed;
  top: 0;
  width: 100%;
  color: black;
  background-color: white;
  max-width: 1920px;
  min-height: 70px;
  overflow: hidden;
  margin: 0 auto;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;
}

.notes-page header .logo {
  font-weight: bold;
}

a {
  text-decoration: none;
  color: black;
}

.notes-page header ul li {
  list-style: none;
  display: inline-block;
  padding: 0 15px;
  margin-top: 15px;
}
.notes-page header ul li a {
  text-transform: uppercase;
  opacity: 0.8;
}
.notes-page header ul li a :hover {
  opacity: 1;
}

.notes-page section {
  width: 100%;
  padding: 20px 0;
}

.note-wrapper {
  max-width: 1920px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  margin: auto;
}

.card-note {
  color: rgb(83, 83, 83);
  background-color: #ebd77d;
  width: 300px;
  box-shadow: 0 0 5px rgb(76, 78, 83);
  padding: 10px;
  position: relative;
  margin: 15px;
  cursor: pointer;
}

.card-note .close {
  position: absolute;
  top: 0;
  right: 10px;
  font-size: 25px;
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

.card-note h3 {
  color: white;
  margin-right: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.card-note .text-wrapper {
  width: 100%;
  height: 150px;
  overflow: hidden;
  margin: 10px 0;
}

.card-note .date {
  text-align: right;
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 50px;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}

.create-note {
  color: white;
  max-width: 500px;
  width: 100%;
  margin: auto;
  border: 10px solid white;
  padding: 30px;
  box-shadow: 0 0 10px rgb(76, 78, 83);
}

.create-note h2 {
  letter-spacing: 2px;
  text-transform: uppercase;
  margin: 10px 0;
}

.create-note .row {
  width: 100%;
  margin: 10px 0;
}

.create-note input {
  width: 100%;
  height: 40px;
  padding: 0 5px;
  border: none;
}

.create-note label {
  text-transform: uppercase;
  font-weight: 600;
  letter-spacing: 2px;
}

.create-note textarea {
  width: 100%;
  border: none;
  resize: none;
  min-height: 150px;
}

.card-note button {
  cursor: pointer;
}

@media (max-width: 500px) {
  .notes-page header ul {
    margin-left: -33px;
  }
  .notes-page header ul li {
    margin-top: -20px;
    margin-bottom: -20px;
    padding: 10px;
  }

  .create-note {
    padding: 15px;
    border: 5px solid;
  }
  /* .register-page img,
  .login-page img {
    margin-left: 60px;
    width: 250px;
    margin-bottom: -40px;
  } */

  .create-note {
    margin-top: 5%;
    max-width: 350px;
  }
}
